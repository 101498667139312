.subscribe {
    text-align: center;
    width: 100%;
    padding-bottom: 4rem;
    background-image: url($subscribe-bgd-img);
    background-repeat: $brand-bgd-repeat;
    background-color: $subscribe-bgd-colour;
    background-position: $brand-bgd-position;
    background-size: $brand-bgd-size;
    .form-group {
        text-align: left;
    }
    @include media-breakpoint-up(md) {
        background-size: $brand-bgd-size / 1.25;
    }
    @include media-breakpoint-up(lg) {
        background-size: $brand-bgd-size / 3.57142857;
    }
    // @include media-breakpoint-up(lg) {
    //     form {
    //         @include make-row();
    //     }
    //     .form-group {
    //         @include make-col-ready();
    //         @include make-col(6);
    //     }
    // }
    input[type="submit"] {
        margin-top: 1rem;
    }
}
