.social-media-info {
    text-align: center;
    @include spacer();
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        display: inline-block;
    }
    a {
        padding: 0 0.5rem;
        display: inline-block;
        text-decoration: none;
        @include transition-property(transform, color);
        @include transition-duration(0.25s);
        &:hover,
        &:focus {
            text-decoration: none;
            color: $brand-secondary;
            @include transform(rotate(360deg));
        }
    }
    i {
        font-size: 3rem;
    }
    header {
        text-transform: capitalize;
    }
}
