.trading-hours-banner {
    display: block;
    background-color: $trading-hours-banner-bg;
    color: $trading-hours-banner-color;
    text-align: left;
    position: relative;
    z-index: 1;
    text-decoration: none;
    height: $trading-hours-banner-height;
    line-height: $trading-hours-banner-height;
    &:focus,
    &:hover {
        color: $trading-hours-banner-color;
        text-decoration: none;
        h4::after {
            right: -0.5rem;
        }
    }
    h4 {
        font-size: $font-size-base*0.75;
        margin: 0;
        font-weight: $lead-font-weight;
        text-transform: capitalize;
        position: relative;
        line-height: $trading-hours-banner-height;
        padding-left: 0.5rem;
        &::after {
            content: $icon-caret-right;
            font-family: 'icomoon';
            position: absolute;
            right: 0.5rem;
            font-size: 0.6rem;
            transition: right 0.25s;
        }
        span {
            display: inline-block;
            font-weight: $font-weight-bold;
            line-height: 1.6;
        }
    }
    @include media-breakpoint-up(lg) {
        text-align: center;
        h4 {
            padding-left: 0;
            &::after {
                position: relative;
                margin-left: 5rem;
            }
        }
    }
}
