
// THE ABOUT PAGE
.about-centre {
    @include spacer();
    .img-wrapper {
        margin-bottom: 2rem;
        img {
            width: 100%;
        }
    }
}

// THE CENTRE DEVELOPMENTS, LEASING OPPORTUNITIES & SERVICES PAGES
.developments,
.centre-services {
    @extend .container;
    ul {
        list-style: none;
        padding: 0;
        @include make-row();
    }
    li {
        @include make-col-ready();
        margin-bottom: 3rem;
        @include media-breakpoint-only(sm) {
            @include make-col(6);
        }
        @include media-breakpoint-up(md) {
            @include make-col(4);
        }
    }
    .service-name {
        font-size: $font-size-lg;
    }
    .service-links a {
        text-decoration: none;
        transition: color 0.25s;
        i {
            color: $gray;
        }
        &:hover,
        &:focus {
            color: $gray;
        }
    }
}

.developments {
    .development-title {
        font-size: $font-size-lg;
    }
}

.centre-services {
    @include spacer();
}

.leasing-opportunities {
    @include spacer();
    @extend .container;
    .contact-details {
        text-align: center;
        .leasing-contact {
            display: block;
            font-size: $font-size-base;
            font-weight: bold;
        }
        a {
            display: block;
            transition: color 0.25s;
            &:hover,
            &:focus {
                color: $gray;
            }
        }
        i {
            color: $gray;
        }
    }
}
