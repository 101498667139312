.btn {
    text-transform: capitalize;
    outline: none;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 1.2rem 0.5rem;
    cursor: pointer;
    font-weight: 100;
    font-family: $headings-font-family;
    font-size: $font-size-xs;
    @include transition-property(color, background-color, border);
    @include transition-duration(0.25s);
}

.btn-primary {
    background-color: $primary-btn-bgd;
    border-color: $primary-btn-border;
    color: $primary-btn-colour;
    &:hover,
    &:focus {
        background-color: $primary-btn-bgd-hover;
        color: $primary-btn-colour-hover;
        border-color: $primary-btn-border-hover;
    }
}

.btn-secondary {
    &:hover,
    &:focus {
        background-color: $white;
        color: $brand-primary;
    }
}

.btn-wrapper {
    text-align: center;
}
