.spacer {
    @include spacer();
}

.center {
    display: flex;
    > div,
    > span,
    > a {
        margin: auto;
    }
}
