.hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 24rem;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        height: 34rem;
    }
}

.hero,
.home-hero {
    .welcome-text {
        color: $white;
        text-align: center;
        @include flexCenter();
        height: 100%;
        width: 100%;
    }
    h1, h2, h3 {
        letter-spacing: $header-letter-spacing*3;
        text-transform: uppercase;
        font-weight: $headings-font-weight*5;
        font-size: $font-size-h2;
    }
    .welcome-text {
        width: 80%;
        @include media-breakpoint-up(xl) {
            width: 60%;
        }
    }
}



.home-hero {
    height: calc(100vh - #{$trading-hours-banner-height} - #{$mobile-toolbar-height});
    width: 100%;
    position: relative;
    overflow: hidden;
    .bgd {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: fixed;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    @include media-breakpoint-up(lg) {
        height: calc(100vh - #{$trading-hours-banner-height});
        .bgd {
            height: 100%;
        }
    }
}

.home-hero {
    .scroll-down {
        position: absolute;
        z-index: 2;
        bottom: 2rem;
        color: $white;
        text-decoration: none;
        margin: 0 auto;
        text-align: center;
        left: 0;
        right: 0;
        display: inline-block;
        width: 2rem;
        transition: color 0.25s;
        i {
            font-size: $font-size-base*2;
        }
        &:hover  {
            color: $brand-secondary;
        }
    }
    h1 {
        font-size: $font-size-h1;
        text-transform: capitalize;
        font-weight: $headings-font-weight;
    }
    .welcome-text h2 {
        margin-bottom: 2rem;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    .welcome-text i {
        font-size: 2.6rem;
        display: $icon-centre-display;
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        .welcome-text h2 {
            font-size: $font-size-base*1.1;
            text-transform: uppercase;
            letter-spacing: 3px;
        }
    }
    @include media-breakpoint-between(sm, lg) {
        h1 {
            font-size: 2rem;
            width: 60%;
            margin: 0 auto;
        }
    }
    @include media-breakpoint-up(lg) {
        .welcome-text h2 {
            font-size: $font-size-sm;
            margin-bottom: 2rem;
        }
    }
}

@include keyframes(bounce) {
    20%, 50%, 20%, 20%, 20% {
        @include transform(translateY(0));
    }
    40% {
        @include transform(translateY(-16px));
    }
    60% {
        @include transform(translateY(-8px));
    }
}

.bounce {
    @include animation(bounce 3s infinite);
}
