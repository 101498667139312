.mobile-toolbar {
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $mobile-toolbar-height;
    background-color: $white;
    transition: bottom 0.25s;
    a  {
        width: 1%;
        display: table-cell;
        text-align: center;
        float: none;
        font-size: $small-font-size;
        font-family: $headings-font-family;
        color: $black;
        padding: 1rem 0.5rem;
        outline: none;
        text-transform: capitalize;
        height: $mobile-toolbar-height;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    i {
        display: block;
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
        color: $brand-primary;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

@media screen and (orientation: portrait) {
    .mobile-toolbar {
        position: fixed;
    }
}

.mobile-toolbar.is-hidden {
    bottom: -$mobile-toolbar-height;
}
