// Tabs on the trading hours page.
.nav-tabs {
    @include display(flex);
    @include align-items(center);
    @include justify-content(center);
    .nav-item {
        margin: 0;
        text-align: center;
        color: $black;
        font-size: $font-size-sm;
        // height: 6rem;
        @include flex(1);
    }
    .nav-link {
        padding: 1rem 0.5rem;
        height: 100%;
        text-decoration: none;
        font-size: 0.75rem;
        i {
            font-size: $font-size-base*1.8;
            display: block;
            margin-bottom: 0.2rem;
        }
        &.active {
            border: none;
        }
    }
}

.tab-pane,
.nav-item {
    &.active {
        background-color: $black;
        color: $white;
    }
}

.tab-content {
    .table {
        th,
        td {
            border: none;
        }
    }
}
