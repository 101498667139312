// // MAIN SITE NAVIGATION

.primary-nav {
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        > li {
            list-style: none;
        }
    }
    a {
        color: $mobile-nav-colour;
        text-decoration: none;
        font-family: $headings-font-family;
    }
}

@include media-breakpoint-down(md) {
    .primary-nav {
        position: fixed;
        width: 100%;
        background: $brand-primary;
        z-index: 3;
        height: 100%;
        padding-bottom: 4rem;
        overflow-x: scroll;
        > ul {
            margin-top: 7rem;
        }
        ul > li {
            text-align: center;
            padding: 1rem 0;
            font-size: $font-size-lg;
            letter-spacing: 2px;
        }
        .alpha-link {
            font-size: $font-size-base*1.5;
            letter-spacing: 3px;
            font-weight: 100;
        }
        .submenu {
            display: none;
            padding-top: 1rem;
            > a {
                display: block;
                font-size: $font-size-base;
                padding: 0.5rem 0;
                font-weight: 100;
                letter-spacing: 1px;
            }
        }
    }
    .dropdown-parent > a::after {
        content: $icon-triangle-down;
        font-family: 'icomoon';
        display: inline-block;
        margin-left: 0.5rem;
        vertical-align: middle;
        font-size: 0.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .primary-nav .mobile-header {
        display: none;
    }
    .primary-nav {
        display: inline-block;
        float: right;
        width: 70%;
        height: $desktop-nav-height;
        line-height: $desktop-nav-height;
        a {
            color: $desktop-nav-colour;
        }
        > ul > li {
            width: 1%;
            display: table-cell;
            text-align: center;
            float: none;
            padding: 0;
            border-bottom: 0;
            position: relative;
        }
        .alpha-link {
            float: none;
            width: 100%;
            display: block;
            cursor: pointer;
            padding: 0;
            font-size: $desktop-nav-font-size;
            letter-spacing: 0.5px;
            text-transform: $nav-text-transform;
            &::before {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                left: 0;
                right: 0;
                background: $white;
                margin: 0 auto;
                bottom: 0;
            }
            &:hover::before {
                width: 30%;
                transition: width 0.25s ease-out;
            }
        }
        .submenu {
            font-size: $font-size-sm;
            padding: 1rem 0;
            visibility: hidden;
            position: absolute;
            left: -50%;
            right: 0;
            top: 100%;
            width: 200%;
            opacity: 0;
            background: transparent;
            @include transform(translateY(-2rem));
            z-index: -1;
            transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
            > a {
                text-align: left;
                padding: 0.5rem 2rem;
                font-size: $font-size-xs;
                display: block;
                text-align: center;
                line-height: 1.5;
                transition: color 250ms;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
        .submenu,
        .submenu * {
            background: transparent;
        }
    }
    .dropdown-parent {
        position: relative;
        &:hover {
            .submenu {
                visibility: visible;
                background: $nav-bgd;
                z-index: 1;
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0s, 0s, 0.3s;/* this removes the transition delay so the menu will be visible while the other styles transition */
            }
        }
        > a::after {
            content: $icon-triangle-down;
            font-family: 'icomoon';
            display: inline-block;
            margin-left: 0.5rem;
            vertical-align: middle;
            font-size: 0.5rem;
        }
    }
}
