// SPECIFIC VARIABLES FOR HERITAGE SPRINGS SHOPPING CENTRE

// COLOURS
$brand-primary:            #000;
$brand-secondary:          #9e9e9e;
$brand-tertiary:           #fff;

//banner
$trading-hours-banner-bg:                $brand-primary;
$trading-hours-banner-color:             #fff;

// NAV
$nav-bgd:                  #2e7749;
$mobile-nav-bgd:           #2e7749;
$nav-bgd-fixed:            #2e7749;
$desktop-nav-colour:       $brand-tertiary;
$mobile-nav-colour:        rgba(0, 0, 0, 0.75);
$desktop-nav-font-size:    1rem;
$desktop-nav-height:       3.6rem;
$nav-text-transform:       capitalize;
$hamburger-colour:         $brand-tertiary;

// IMAGE OVERLAY COLOUR
$overlayColour:            rgba(0, 0, 0, 0.5);

// LOGO VARIABLES
// ICON
$brand-logo-icon:          '/img/logos/logo-ferntree.png';
$brand-logo-icon-width:    8.75rem;
$brand-logo-icon-height:   2.375rem;
$brand-logo-icon-display:  block;
$mobile-logo-width:        8.75rem;
$mobile-logo-height:       2.375rem;

$brand-logo:               '/img/logos/logo-ferntree.png';
$brand-logo-width:         2rem;

$logoTextIndent:           -9999px;
$logo-centre-name-display: none;

// SUBSCRIBE SECTION
$brand-bgd-img:            none;
$brand-bgd-repeat:         repeat;
$brand-bgd-colour:         #fff;
$brand-bgd-position:       top left;
$brand-bgd-size:           70%;

// SUBSCRIBE SECTION
$subscribe-bgd-colour:     #2e7749;
$subscribe-bgd-img:        none;

// ICON FOR CENTRE
$icon-centre-specific:     "\e91f";
$icon-centre-display:      none;

// TYPOGRAPHY
$font-family-sans-serif:     'Raleway', sans-serif;
$font-family-serif:          $font-family-sans-serif;
$font-family-base:           $font-family-sans-serif;
$headings-font-family:       $font-family-sans-serif;
$font-size-base:             1rem;
$lead-font-weight:           100;
$header-letter-spacing:      0.5px;
$font-weight-bold:              400;
$headings-font-weight:          500;

$icomoon-font-size:             1.4rem;

//BUTTONS
$primary-btn-bgd:            $brand-secondary;
$primary-btn-border:         $brand-secondary;
$primary-btn-colour:         #fff;
$primary-btn-bgd-hover:      $brand-secondary;
$primary-btn-border-hover:   $brand-secondary;
$primary-btn-colour-hover:   $brand-tertiary;

@import './base';
