
.filter-bar {
    width: 100%;
    margin-bottom: 2rem;
    @include clearfix();
    @extend .container;
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
    @include media-breakpoint-up(md) {
        max-width: 33.333333%;
        margin-left: 33.333333%;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

.filter-btn {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    width: 33.333333%;
    float:left;
    padding: 1rem;
    box-sizing: border-box;
    color: $brand-primary;
    background: $white;
    outline: none;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid $brand-secondary;
    font-size: $font-size-sm;
    transition: all 0.25s ease;
    &.active,
    &:hover {
        background: $brand-primary;
        color: $white;
    }
    &:focus {
        outline: none;
    }
    i {
        display: block;
        margin-bottom: 0.5rem;
        color: $brand-secondary;
    }
}

// DON'T REMOVE - NEEDED FOR THE MIXITUP PLUGIN
// .articles-list .article {
// 	display: none;
// }

.articles-list,
.offers-list {
    list-style: none;
    padding: 0;
    @include make-row();
    .article,
    .offer {
        @include make-col-ready();
        margin-bottom: 4rem;
        @include media-breakpoint-between(sm, lg) {
            @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
        time {
            display: block;
            text-align: center;
            margin-bottom: 1rem;
            font-weight: $lead-font-weight;
            i {
                margin-right: 0.5rem;
                color: $brand-secondary;
            }
        }
        h3 {
            font-size: $font-size-lg;
        }
    }
}

.offers-list {
    margin-top: 2rem;
}

.article-link,
.offer-link {
    background-color: $black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 14rem;
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 2rem;
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none;
        color: $white;
        &::before {
            background: rgba(0, 0, 0, 0.5);
        }
        p::after {
            width: 50%;
            transition: width 0.25s ease-out;
        }
    }
    &.overlay::before {
        transition: background 0.25s;
    }
    @include media-breakpoint-down(sm) {
        margin-left: -15px;
        margin-right: -15px;
    }
    header {
        color: $white;
        text-align: center;
        @include flexCenter();
        height: 100%;
        width: 100%;
        @extend .container;
        p {
            position: relative;
            margin-bottom: 0;
            display: inline-block;
            font-family: $headings-font-family;
            &::after {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                left: 0;
                right: 0;
                background: $white;
                margin: 0 auto;
                bottom: 0;
            }
        }
    }
}

.article-pg,
.offer-pg {
    @include spacer();
    .section-header {
        time > i {
            margin-right: 0.5rem;
        }
    }
}

.article-pg {
    .section-header {
        time {
            display: block;
            padding-bottom: 0.5rem;
        }
    }
}

.offer-pg {
    .store-time-banner {
        margin-top: 2rem;
        padding: 1rem 0;
        a {
            cursor: pointer;
            text-decoration: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .store-time-banner {
            background-color: $brand-primary;
            margin-bottom: 2rem;
            padding: 0;
            height: 4rem;
            line-height: 4rem;
            font-size: $font-size-sm;
            a {
                color: $white;
            }
            time {
                float: right;
                display: inline-block;
                &::after {
                    content: $icon-caret-right;
                    font-family: 'icomoon';
                    margin-left: 1rem;
                    vertical-align: middle;
                }
            }
        }
    }
    .store-links a {
        display: inline-block;
        text-decoration: none;
        &:first-child {
            margin-right: 1rem;
        }
        span {
            display: inline-block;
            transition: color 0.25s;
        }
        &:hover,
        &:focus {
            span {
                text-decoration: underline;
                color: $brand-secondary;
            }
        }
        i {
            color: $brand-secondary;
        }
    }
}
