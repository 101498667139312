.open-menu {
    transition: right 0.25s, left 0.25s;
    height: 1.6rem;
    margin-top: -0.8rem;
    width: 2rem;
    > div {
        width: 2rem;
        height: 3px;
        background-color: $hamburger-colour;
        position: absolute;
        transition: all 0.3s;
    }
    .bar1 {
        top: 0;
    }
    .bar2 {
        top: 50%;
        margin-top: -1.5px;
    }
    .bar3 {
        bottom: 0;
    }
}
.open-nav .open-menu {
    > div {
        height: 1px;
    }
    .bar1 {
        top: 0.8rem;
    }
    .bar3 {
        bottom: 0.8rem;
    }
}

// DELAY CLOSE BY 0.3S AND ANIMATE THE
//CLOSE BUTTON AND THEN CLOSE THE MENU
.close-menu {
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    top: calc(#{$trading-hours-banner-height} + #{$header-height/2});
    opacity: 1;
    margin-left: 0;
    > span {
        height: 30px;
        width: 1px;
        color: $white;
        content: '';
        background-color: $white;
        position: absolute;
        @include transition-property(transform, height, width);
        @include transition-duration(0.3s);
    }
    .bar1 {
        @include transform(rotate(90deg));
    }
    .bar2 {
        @include transform(rotate(-90deg));
    }
}

.open-nav .close-menu {
    > span {
        height: 30px;
        width: 2px;
    }
    .bar1 {
        @include transform(rotate(45deg));
    }
    .bar2 {
        @include transform(rotate(-45deg));
    }
}

//
// .topbar.topbar-fixed {
//     .open-menu {
//         .bar1 {
//             width: 0.8rem;
//             right: 0;
//         }
//         .bar2 {
//             width: 1.4rem;
//             right: 0;
//         }
//     }
// }
