
@include media-breakpoint-down(md) {
    .primary-nav {
        top: 0;
        left: 0;
        transform: (translate3d(0, 100%, 0));
        transition-property: transform;
        transition-duration: 0.4s;
        @include transition-delay(0.4s);
        transition-timing-function: cubic-bezier(.694, .0482, .335, 1);
    }

    .open-nav .primary-nav {
        transform: (translate3d(0, 0, 0));
        transition-property: transform;
        transition-duration: 0.4s;
        @include transition-delay(0.4s);
        transition-timing-function: cubic-bezier(.694, .0482, .335, 1);
    }

    // .open-nav .site-content-wrapper {
    //     height: 100vh;
    //     overflow: hidden;
    // }

}
