.site-footer {
    @extend .container;
    padding-bottom: 2rem;
    font-size: $font-size-sm;
    .scroll-top {
        transition: color 0.25s;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $brand-secondary;
        }
        i {
            display: inline-block;
        }
    }
    @include media-breakpoint-down(lg) {
        .scroll-top,
        .id-digital {
            display: block;
        }
        .scroll-top {
            margin-bottom: 2rem;
        }
        .id-digital {
            margin: 0.2rem 0;
        }
    }
    @include media-breakpoint-up(lg) {
        .scroll-top {
            float: left;
        }
        .id-digital {
            float: right;
        }
    }
}

.footer-bottom {
    text-align: center;
    a:hover,
    a:focus {
        color: $brand-primary;
    }
}

.mobile-footer-links {
    text-align: center;
    @include spacer();
    @include media-breakpoint-up(lg) {
        display: none;
    }
    ul {
        margin: 0;
        list-style: none;
        padding: 0;
    }
    li {
        display: block;
        margin: 0.5rem 0;
    }
    a {
        padding: 0.5rem;
        text-decoration: underline;
        display: block;
        text-transform: capitalize;
    }
}

.footer-links {    
    text-align: center;
    @include spacer();
    
    ul {
        margin: 0;
        list-style: none;
        padding: 0;
    }
    li {        
        margin: 0.5rem 0;
    }
    select {
        width: 300px;
    }    
    a {
        padding: 0.5rem;
        text-decoration: underline;
        display: block;
        text-transform: capitalize;
    }
}

@include keyframes(bounceUp) {
    20%, 50%, 20%, 20%, 20% {
        @include transform(translateY(0));
    }
    40% {
        @include transform(translateY(16px));
    }
    60% {
        @include transform(translateY(8px));
    }
}

.bounce-up {
    @include animation(bounceUp 3s infinite);
}
