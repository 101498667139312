// Colours
$white:                         #fff;
$black:                         #090A0F;
$light-gray:                    #eee;
$vegas-gold:                    #C5B358;

$header-height:                 5.5rem;
$trading-hours-banner-height:   2.5rem;
$mobile-toolbar-height:         5rem;
$fixed-header-height:           4rem;

$mobile-map-height:             16rem;
$tablet-map-height:             22rem;
$desktop-map-height:            18rem;

