.travel-info {
    @extend .container;
    @include spacer();
    > h3 {
        text-align: center;
        letter-spacing: 2px;
        margin-bottom: 1.5rem;
        font-size: $font-size-base*1.6;
    }
    .travel-section {
        margin-bottom: 2rem;
        header {
            text-align: left;
            margin-bottom: 1rem;
            vertical-align: middle;
        }
        i {
            vertical-align: middle;
            font-size: 1.8rem;
            margin-right: 0.5rem;
            color: $brand-secondary;
        }
        h4 {
            font-size: $font-size-base;
            font-weight: $lead-font-weight;
            display: inline-block;
            margin: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up(lg) {
        .travel-section {
            @include spacer();
        }
    }
}
