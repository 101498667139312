
a {
    outline: none;
    text-decoration: underline;
    color: $black;
}

i[class^="icon-"] {
    font-size: $icomoon-font-size;
    vertical-align: middle;
}
