.topbar {
    .centre-title {
        display: inline-block;
        color: $white;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-size: $font-size-base;
        margin: 0;
        padding: 0;
        position: relative;
    }
    a {
        text-decoration: none;
    }
}

.centre-title .logo {
    display: inline-block;
    .centre-name {
        font-family: $headings-font-family;
        display: $logo-centre-name-display;
        text-indent: $logoTextIndent;
        margin-left: $brand-logo-icon-width + 0.5rem;
    }
    .logo-icon {
        background-repeat: no-repeat;
        background-image: url($brand-logo-icon);
        background-size: contain;
        width: $mobile-logo-width;
        height: $mobile-logo-height;
        display: $brand-logo-icon-display;
        margin-top: -$mobile-logo-height/2;
        position: absolute;
        top: 50%;
    }
}

@include media-breakpoint-down(md) {
    .topbar {
        @include clearfix();
        display: block;
        height: $header-height;
        line-height: $fixed-header-height;
        padding: 0 1rem;
        background-color: $mobile-nav-bgd;
        max-width: 80rem;
        width: 100%;
        text-align: center;
        @include make-container();
        position: absolute;
        top: $trading-hours-banner-height;
        left: 0;
        z-index: 1;
        transition: height 0.25s, background 0.25s;
        .centre-title {
            display: inline-block;
            float: left;
            opacity: 0;
            z-index: -1;
        }
        // .site-logo {
        //     display: block;
        //     text-indent: -9999px;
        //     width: $brand-logo-width;
        //     height: $header-height;
        //     line-height: $header-height;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     background-position: center;
        //     background-image: url($brand-logo);
        // }
    }

    /* Reveal open/close buttons */
    .open-menu,
    .close-menu {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        margin: -1.25rem auto 0 -1rem;
        z-index: 2;
        cursor: pointer;
    }

    .topbar.topbar-fixed {
        position: fixed;
        top: 0;
        left: 0;
        height: auto;
        z-index: 3;
        width: 100%;
        background-color: $nav-bgd-fixed;
        .centre-title {
            opacity: 1;
            z-index: 1;
            transition: all 0.25s ease-in 0.25s;
        }
        .open-menu {
            left: auto;
            right: 1rem;
        }
    }
}


// .site-header {
//     .topbar,
//     .mobile-header {
//         @include clearfix();
//         display: block;
//         height: $header-height;
//         line-height: $header-height;
//         padding: 0 1rem;
//         width: 100%;
//     }
//     h1 {
//         display: none;
//         margin: 0;
//         padding: 0;
//     }
//     .site-logo {
//         display: block;
//         text-indent: -9999px;
//         width: $brand-logo-width;
//         height: $header-height;
//         line-height: $header-height;
//         background-repeat: no-repeat;
//         background-size: contain;
//         background-position: center;
//         background-image: url($brand-logo);
//     }
//     a {
//         text-decoration: none;
//     }
// }

// .desktop-header {
//     display: none;
// }

// .primary-nav {
//     width: 100%;
//     position: absolute;
//     right: -100%;
//     top: 0;
//     background: $brand-primary;
//     z-index: 3;
//     @include media-breakpoint-down(lg) {
//         min-height: 100%;
//     }
//     .mobile-header {
//         position: relative;
//         text-align: center;
//         .home-link {
//             position: absolute;
//             left: 0;
//             right: 0;
//             margin: 0 auto;
//             &:focus,
//             &:hover {
//                 text-decoration: none;
//             }
//         }
//     }
// }

// .primary-nav {
//     a {
//         color: $white;
//     }
//     ul {
//         width: 100%;
//         margin: 0;
//         padding: 0;
//         > li {
//             padding: 0.5rem 1rem;
//             box-sizing: border-box;
//             list-style: none;
//             width: 100%;
//             border-bottom: 1px solid $white;
//             float: left;
//             display: block;
//             &:hover,
//             &:focus,
//             &:active {
//                 > a {
//                     text-decoration: none;
//                 }
//             }
//         }
//     }
//     .alpha-link {
//         text-transform: uppercase;
//         width: 50%;
//         display: inline-block;
//         float: left;
//     }
//     .submenu {
//         display: inline-block;
//         float: left;
//         width: 50%;
//         > a {
//             display: block;
//             text-transform: capitalize;
//             &:hover,
//             &:focus {
//                 text-decoration: underline;
//             }
//         }
//     }
//     .alpha-link,
//     .submenu > a {
//         padding: 0.5rem 0;
//         text-decoration: none;
//     }
// }

@include media-breakpoint-up(lg) {
    .site-header {
        background-color: $nav-bgd;
        width: 100%;
        position: absolute;
        z-index: 1;
        .desktop-wrapper {
            @include make-container();
            max-width: 80rem;
            width: auto;
        }
    }
    .open-menu {
        display: none;
    }
    .topbar {
        display: inline-block;
        float: left;
        width: 30%;
        .centre-title {
            line-height: $desktop-nav-height;
        }
    }
    .centre-title .logo {
        height: 100%;
        .logo-icon {
            width: $brand-logo-icon-width;
            height: $brand-logo-icon-height;
            margin-top: -$brand-logo-icon-height/2;
        }
    }
}
