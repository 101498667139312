@mixin flexCenter() {
    @include display(flex);
    @include align-items(center);
    @include justify-content(center);
}

@mixin spacer() {
    margin-bottom: 4rem;
}

@mixin closeBtn($colour) {
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    opacity: 1;
    > span {
        height: 48px;
        width: 1px;
        color: $colour;
        background-color: $colour;
        position: absolute;
        transition: all 0.3s;
    }
    .bar1 {
        transform: rotate(0deg);
    }
    .bar2 {
        transform: rotate(0deg);
    }
}
