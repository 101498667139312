
.home-hero + .centre-bgd-pattern {
    padding-top: 4rem;
}

.promo-blocks {
    h3 {
        font-size: $font-size-lg;
    }
    .promo {
        margin: 0;
        @include make-col-ready();
        @include make-col(12);
    }
    @include media-breakpoint-down(md) {
        .promo {
            padding-left: 0;
            padding-right: 0;
        }
    }
    @include media-breakpoint-up(sm) {
        .offer-link {
            height: 20rem;
        }
    }
    @include media-breakpoint-up(lg) {
        padding-top: 0;
        background-color: $white;
        width: 100%;
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
        .promo {
            @include make-col(6);
            margin: 0;
        }
        .offer-link {
            height: 22rem;
            margin-bottom: 0;
        }
    }
}
.intro-blocks{
    background-color: $white;

    @include media-breakpoint-up(lg) {
        padding-top: 0;
        background-color: $white;
        width: 100%;

    }
    @include media-breakpoint-up(sm) {
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
    }
    @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
    }

}

.news-events {
    width: 100%;
}

.offers-promotions {
    @include spacer();
    margin-top: 2rem;
    @include media-breakpoint-up(md) {
        padding-bottom: 4rem;
        background-color: $white;
        max-width: 80rem;
        margin: 0 auto;
    }
}

.centre-bgd-pattern {
    background-image: url($brand-bgd-img);
    background-repeat: $brand-bgd-repeat;
    background-color: $brand-bgd-colour;
    background-position: $brand-bgd-position;
    background-size: $brand-bgd-size;
    padding-top: 2rem;
    padding-bottom: 4rem;
    > .home-content-container {
        background-color: $white;
        margin-left: $grid-gutter-width-base /2;
        margin-right: $grid-gutter-width-base /2;
    }
    @include media-breakpoint-up(md) {
        background-size: $brand-bgd-size / 1.25;
        padding-bottom: 0;
        padding-top: 0;
    }
    @include media-breakpoint-up(lg) {
        background-size: $brand-bgd-size / 3.57142857;
    }
}

.home-section-header {
    text-align: center;
    background: $white;
    margin-left: $grid-gutter-width-base /2;
    margin-right: $grid-gutter-width-base /2;
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
    > h2, h3, h4, h5, h6 {
        margin: 0;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-top: 5rem;
        padding-bottom: 3rem;
        background-color: $white;
        width: 100%;
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.btn-wrapper {
    background: $white;
    text-align: center;
    @extend .container;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 80rem;
    }
}
.centre-location {
    background: $white;
    margin-left: $grid-gutter-width-base /2;
    margin-right: $grid-gutter-width-base /2;
    padding-bottom: 2rem;
    @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
    }
}
