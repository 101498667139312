html,
body {
    height: 100%;
}

.sreveal {
    visibility: hidden;
}

#wrapper {
    overflow-x: hidden;
    width: 100%;
}

.site-content-wrapper {
    //padding-bottom: $mobile-toolbar-height;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.section-header {
    padding: 4rem 1.5rem 0.5rem;
    text-align: center;
    background-color: $white;
    color: $black;
    h1, h2, h3, h4, h5, h6 {
        font-size: $font-size-h4;
        letter-spacing: $header-letter-spacing*2;
        text-transform: capitalize;
        font-weight: $headings-font-weight;
        line-height: 1.4;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 6rem;
        padding-bottom: 2rem;
        font-size: $font-size-h5;
        font-weight: $headings-font-weight;
    }
    .dark-bgd & {
        background-color: transparent;
        color: $white;
    }
}

.overlay {
    &::before {
        content: '';
        background-color: $overlayColour;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}

.overlay * {
    position: relative;
}

.dark-bgd {
    color: $white;
    .section-header::after {
        color: $white;
    }
}

.content-wrapper {
    @include make-row();
    > div {
        @include make-col-ready();
        @include media-breakpoint-up(sm) {
            @include make-col(10);
            @include make-col-offset(1);
        }
        @include media-breakpoint-up(md) {
            @include make-col(8);
            @include make-col-offset(2);
        }
    }
}

.full-width-mobile {
    img {
        width: 100%;
        margin-bottom: 2rem;
    }
}

.full-width-img img {
    width: 100%;
    height: auto;
}

.current-day {
    background-color: $brand-primary;
    color: $white;
}

@include media-breakpoint-down(sm) {
    .container.full-width-mobile {
        padding-left: 0;
        padding-right: 0;
    }
    .full-width-img {
        img {
            width: calc(100% + #{$grid-gutter-width-base});
            height: auto;
            margin-left: -$grid-gutter-width-base/2;
        }
    }
}

.site-footer-wrapper {
    background: $white;
    margin-left: $grid-gutter-width-base /2;
    margin-right: $grid-gutter-width-base /2;
    @include media-breakpoint-up(lg) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 80rem;
    }
}

.error-pg .welcome-text {
    .go-back {
        color: $white;
        text-decoration: none;
        transition: color 0.25s;
         > i {
            font-size: 0.8rem;
            margin-bottom: 0;
        }
        > h2, i {
            display: inline-block;
            line-height: 1;
        }
        &:hover, &:focus {
            color: $brand-primary;
        }
    }
}
