
.gmap {
    width: 100%;
    height: $mobile-map-height;
    margin-bottom: 2rem;
    @include media-breakpoint-between(sm, lg) {
        height: $tablet-map-height;
    }
    @include media-breakpoint-up(lg) {
        height: $desktop-map-height;
    }
}

.centre-location {
    @include media-breakpoint-up(md) {
        > .wrapper {
            @include make-row();
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        .map-container {
            @include make-col-ready();
            @include make-col(6);
            float: right;
        }
    }
    @include media-breakpoint-up(lg) {
        .map-container {
            @include make-col(4);
        }
    }
}

.contact-details {
    padding: 0 2rem;
    @include spacer();
    @include media-breakpoint-up(md) {
        @include make-col-ready();
        @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(4);
        a {
            text-decoration: none;
        }
    }
}
