.store-finder-results {
    @include spacer();
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @include make-row();
    }
    li {
        @include make-col-ready();
        margin-bottom: 2rem;
        > a {
            padding: 1rem;
            border: 1px solid $brand-secondary;
            display: block;
            margin-bottom: 1rem;
            &:hover,
            &:focus {
                .store-logo {
                    transform: scale(0.9);
                }
            }
        }
    }
    .finder-results-title {
        text-align: center;
        font-size: $font-size-lg;
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(sm) {
        li {
            @include make-col(6);
        }
    }
    @include media-breakpoint-up(lg) {
        li {
            @include make-col(4);
            margin-bottom: 4rem;
        }
    }
    .store-logo {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 12rem;
        width: 100%;
        margin: 0 auto;
        transition: transform 0.25s;
    }
    figure {
        padding: 1rem;
        border: 1px solid $brand-secondary;
    }
    .store-name {
        font-size: $font-size-lg;
    }
    .map-link {
        margin-right: 1rem;
        display: block;
        margin-bottom: .5rem;
    }
    .store-links {
        letter-spacing: 1px;
        a {
            text-decoration: none;
            transition: color 0.25s;
            &:hover,
            &:focus {
                color: $gray-dark;
            }
        }
        i {
            color: $brand-secondary;
        }
    }
}


.store-pg {
    @include spacer();
    @extend .container;
    .store-header {
        @include make-row();
        margin-top: 2rem;
    }
    .store-name,
    .store-logo {
        @include make-col-ready();
    }
    .store-name {
        @include make-col(7);
        font-size: $font-size-h4;
        letter-spacing: 1px;
    }
    .store-logo {
        @include make-col(5);
        > div {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 6rem;
            width: 100%;
            margin: 0 auto;
            border: 1px solid $brand-secondary;
        }
    }
    .store-links {
        width: 100%;
        a {
            text-decoration: none;
            display: block;
            margin-bottom: 1rem;
            transition: color 0.25s;
            &:hover,
            &:focus {
                color: $gray-dark;
            }
        }
        i {
            color: $brand-secondary;
        }
    }
    @include media-breakpoint-up(lg) {
        .store-header {
            display: none;
        }
    }
    @include media-breakpoint-down(md) {
        .store-hours {
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 2rem;
        }

    }
}
