$icomoon-font-path: "../fonts/icomoon";

$icon-service-bell: "\e921";
$icon-plus: "\e91c";
$icon-minus: "\e91d";
$icon-ticket: "\e91e";
$icon-triangle-down: "\e91b";
$icon-arrow-down: "\e900";
$icon-arrow-up: "\e901";
$icon-bus: "\e902";
$icon-calendar: "\e903";
$icon-car: "\e904";
$icon-caret-left: "\e905";
$icon-caret-right: "\e906";
$icon-clapperboard: "\e908";
$icon-clock: "\e909";
$icon-cutlery: "\e90a";
$icon-earth: "\e90b";
$icon-envelope: "\e90c";
$icon-external-link: "\e90d";
$icon-facebook: "\e90e";
$icon-home: "\e90f";
$icon-info: "\e910";
$icon-instagram: "\e911";
$icon-list: "\e912";
$icon-newspaper: "\e913";
$icon-pin: "\e914";
$icon-search: "\e915";
$icon-smartphone: "\e916";
$icon-taxi: "\e917";
$icon-train: "\e918";
$icon-twitter: "\e919";
$icon-worldwide: "\e91a";
$icon-civic-square: "\e907";
