.carousel {
    width: 100%;
    @include media-breakpoint-up(md) {
        max-width: 80rem;
        @include make-container();
        background-color: $white;
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        color: $white;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 0.8rem;
        border: 0;
        outline: none;
        margin-top: -1.3rem;
        transition: background 0.25s;
        cursor: pointer;
        &:active,
        &:hover,
        &:focus {
            background-color: rgba(0, 0, 0, 0.9);
        }
        i {
            font-size: 1.8rem;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    header {
        width: 60%;
        height: 100%;
        @include flexCenter();
        margin: 0 auto;
        h4 {
            font-size: $font-size-lg;
        }
        p {
            position: relative;
            margin-bottom: 0;
            display: inline-block;
            &::after {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                left: 0;
                right: 0;
                background: $white;
                margin: 0 auto;
                bottom: 0;
            }
        }
    }
}

.carousel-link {
    background-color: $black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 12rem;
    position: relative;
    text-align: center;
    overflow: hidden;
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: $white;
    @include media-breakpoint-only(sm) {
        height: 20rem;
    }
    @include media-breakpoint-up(md) {
        margin: 0 1rem;
        height: 12rem;
    }
    &::before {
        content: '';
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: background 0.25s;
    }
    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
        &::before {
            background: rgba(0, 0, 0, 0.5);
        }
        p::after {
            width: 50%;
            transition: width 0.25s ease-out;
        }
    }
}

.carousel-link * {
    position: relative;
    // Hack needed for overlay
}
