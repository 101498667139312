.modal {
    background-color: $white;
    color: $black;
    .modal-header {
        text-align: center;
        border-bottom: 0;
        > div {
            margin: auto;
        }
        h2, h3, h4, h5, h6 {
            font-size: $font-size-base*2.8;
            letter-spacing: 3px;
            text-align: center;
            text-transform: capitalize;
        }
    }
    .close {
        @include closeBtn($black);
        display: block;
        float: none;
        margin: 1.5rem auto 2rem;
        padding: 0 1rem;
    }
}


.modal-open .close {
    > span {
        height: 30px;
        width: 2px;
    }
    .bar1 {
        @include transform(rotate(45deg));
    }
    .bar2 {
        @include transform(rotate(-45deg));
    }
}

#how-to-get-here-modal {
    .travel-info {
        margin-top: 4rem;
    }
}
