.contact {
    max-width: 80rem;
    @include make-container();
    @include spacer();
}

.contact-details {
    .address,
    .tel {
        display: block;
        margin-bottom: 1rem;
    }
    a {
        text-decoration: none;
    }
}

.gmap-btn-wrapper {
    text-align: center;
    .btn {
        margin-top: 1rem;
    }
    @include media-breakpoint-up(lg) {
        text-align: left;
        margin-left: 2rem;
        margin-top: 1rem;
        .btn {
            margin-top: 0;
        }
    }
}
