.search-bar {
    @include transform(translateX(-200%));
    @include transition-property(transform);
    @include transition-duration(1s);
    @include transition-delay(0.4s);
    @include transition-timing-function(cubic-bezier(.694, .0482, .335, 1));
    height: $header-height;
    position: absolute;
    top:0;
    width: 100%;
    background-color: $white;
    left: 0;
    right: 0;
    &.open {
        @include transform(translateX(0));
        @include transition-property(transform);
        @include transition-delay(0s);
        z-index: 9999;
    }
    .close-search {
        float: right;
        position: relative;
    }
    .wrapper {
        @include make-container();
        max-width: 80rem;
        position: relative;
    }
}

.close-search {
    cursor: pointer;
    position: relative;
    width: auto;
    height: 30px;
    top: 1.8rem;
    opacity: 1;
    padding: 0 1rem;
    &:hover {
        > span {
            background-color: $brand-secondary;
        }
    }
    > span {
        height: 30px;
        width: 1px;
        content: '';
        background-color: $black;
        position: absolute;
        @include transition-property(transform, width, background-color);
        @include transition-duration(0.3s);
    }
    .bar1 {
        @include transform(rotate(90deg));
    }
    .bar2 {
        @include transform(rotate(-90deg));
    }

}

.search-bar.open {
    .close-search {
        > span {
            height: 30px;
            width: 1px;
        }
        .bar1 {
            @include transform(rotate(45deg));
        }
        .bar2 {
            @include transform(rotate(-45deg));
        }
    }
}


.search-form {
    float: left;
    width: 84%;
    height: $header-height;
    line-height: $header-height;
    position: relative;
    input[type="text"] {
        border-bottom: 1px solid $brand-secondary;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        width: 80%;
        line-height: 2.2;
        outline: none;
        &::before {
            content: $icon-search;
            font-family: 'icomoon';
        }
        @include placeholder {
            color: $brand-secondary;
        }
    }
    .btn {
        position: absolute;
        margin-top: -1.2rem;
        right: 0;
        top: 50%;
    }
}
