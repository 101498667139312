@import "base/icomoon-variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?daa2t6');
  src:  url('#{$icomoon-font-path}/icomoon.eot?daa2t6#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?daa2t6') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?daa2t6') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?daa2t6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-service-bell {
  &:before {
    content: $icon-service-bell;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket;
  }
}
.icon-triangle-down {
  &:before {
    content: $icon-triangle-down;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-bus {
  &:before {
    content: $icon-bus;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-car {
  &:before {
    content: $icon-car;
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left;
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}
.icon-clapperboard {
  &:before {
    content: $icon-clapperboard;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-cutlery {
  &:before {
    content: $icon-cutlery;
  }
}
.icon-earth {
  &:before {
    content: $icon-earth;
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-newspaper {
  &:before {
    content: $icon-newspaper;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-smartphone {
  &:before {
    content: $icon-smartphone;
  }
}
.icon-taxi {
  &:before {
    content: $icon-taxi;
  }
}
.icon-train {
  &:before {
    content: $icon-train;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-worldwide {
  &:before {
    content: $icon-worldwide;
  }
}
.icon-civic-square {
  &:before {
    content: $icon-civic-square;
  }
}
