.centre-map-pg {
    @extend .container;
    @include spacer();
}

.centre-map {
    position: relative;
    .map-container {
        position: relative;
    }
    .map-img-wrapper {
        border: 1px solid $black;
        height: calc(80vh - #{$mobile-toolbar-height});
        margin: 0;
    }
    .panzoom {
        height: 100%;
        float: left;
    }
    @include media-breakpoint-only(lg) {
        .map-img-wrapper {
            height: 32rem;
        }
    }
    @include media-breakpoint-up(lg) {
        .panzoom {
            height: auto;
            width: 100%;
        }
    }
    @include media-breakpoint-up(xl) {
        .map-img-wrapper {
            height: 40rem;
        }
    }
    .map-controls {
        display: block;
        text-align: right;
        width: 100%;
        margin: 0 auto;
        position: absolute;
        right: 0;
        bottom: -1.5rem;
        z-index: 1;
        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            background-color: $black;
            opacity: 0.8;
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid $black;
            border-radius: 50%;
            cursor: pointer;
        }
    }
    .zoom-in,
    .zoom-out,
    .reset {
        font-size: $font-size-sm;
        border: 2px solid $black;
        background-color: $black;
        color: $white;
        display: block;
        margin: 0;
        position: absolute;
        right: 0.5rem;
        cursor: pointer;
        transition: color 0.25s, background 0.25s;
        &:hover,
        &:focus {
            color: $black;
            background-color: $white;
        }
    }
    .zoom-in,
    .zoom-out {
        height: 2rem;
        width: 2rem;
        padding: 0;
        i {
            font-size: $font-size-base;
        }
    }
    .zoom-in {
        bottom: 6.1rem;
    }
    .zoom-out {
        bottom: 4rem;
    }
    .reset {
        bottom: 2rem;
    }
    .zoom-range {
        -webkit-appearance: none;
        background-color: $black;
        width: 100%;
        height: 2px;
        margin: 1rem 0 0;
        display: block;
    }
    .toggle-levels {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1;
        > button {
            display: block;
            color: $white;
            background-color: $black;
            height: 2rem;
            width: 2rem;
            border: none;
            text-align: center;
            cursor: pointer;
            margin-bottom: 0.2rem;
            outline: none;
            padding: 0;
        }
    }
}

.map-key {
    .columns {
        column-count: 1;
    }
    h4 {
        font-size: $font-size-lg;
    }
    ul {
        list-style: none;
        margin: 0 0 2rem;
        padding: 0;
        span {
            color: $gray-dark;
        }
    }
    @include media-breakpoint-up(sm) {
        .columns {
            column-count: 3;
        }
    }
}
