.cinema {
    @include spacer();
    max-width: 80rem;
    @include make-container();
    .buy-tickets {
        text-align: center;
    }
}

.movies,
.movie-panel {
    ul {
        padding: 0;
        list-style: none;
        margin: 0;
    }
}



.movies,
.movie-panel .dates {
    li {
        @include make-col(6);
    }
    @include media-breakpoint-up(md) {
        li {
            @include make-col(4);
        }
    }
    @include media-breakpoint-up(lg) {
        li {
            @include make-col(3);
        }
    }
    a {
        width: 100%;
        @include flexCenter();
        text-align: center;
        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }
}

.movies {
    @include make-row();
    a {
        color: $white;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        padding: 1rem;
        overflow: hidden;
        cursor: pointer;
        &:hover,
        &:focus {
            &::before {
                background: rgba(0, 0, 0, 0.5);
            }
            .movie-title::after {
                width: 50%;
                transition: width 0.25s ease-out;
            }
        }
        &.overlay::before {
            transition: background 0.25s;
        }
    }
    .movie-title {
        font-size: $font-size-sm;
        letter-spacing: 1px;
        position: relative;
        margin-bottom: 0;
        padding-bottom: 0.5rem;
        display: inline-block;
        &::after {
            content: '';
            width: 0;
            height: 1px;
            position: absolute;
            left: 0;
            right: 0;
            background: $white;
            margin: 0 auto;
            bottom: 0;
        }
    }
}

.movie-panel {
    display: block;
    width: 100%;
    float: left;
}

.movie-info {
    color: $white;
    h4 {
        font-size: $font-size-base;
        line-height: 1.6;
    }
}

.dates {
    color: $black;
    a {
        padding: 1px;
        position: relative;
        transition: color, background 0.25s;
        &::after {
            border: 1px solid $black;
            width: 100%;
        }
        > div {
            position: absolute;
            width: 100%;
            display: block;
            text-align: center;
        }
        &:hover,
        &:focus,
        &:active {
            color: $white;
            background-color: $brand-primary;
        }
    }
    .day,
    .date {
        line-height: 1;
    }
    .day {
        text-transform: uppercase;
    }
    .date {
        font-size: 3.5rem;
        margin-bottom: 2rem;
    }
    time {
        margin-top: 1rem;
        display: block;
    }
}

.cinema-section-header {
    margin-bottom: 2rem;
    text-align: center;
    @include make-col-ready();
    @include make-col(12);
    h5 {
        font-size: $font-size-base;
    }
}

.selected-movie {

    @include media-breakpoint-up(sm) {

    }
}

.selected-movie {
    @include spacer();
    position: relative;
    @extend .overlay;
    margin: 0 0 2rem;
    height: 12rem;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    background-position: center;
    @include flexCenter();
    @include media-breakpoint-up(sm) {
        height: 14rem;
        @include make-col(6);
        @include make-col-offset(3);
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
    }
}

.selected-date {
    text-align: center;
    display: block;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-dark;
    time {
        display: block;
        margin-bottom: 1rem;
    }
    h4 {
        font-size: $font-size-base;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 4rem;
        margin-bottom: 4rem;
    }
}

.movie-time-panel {
    @include spacer();
    li {
        padding: 1px;
    }
    a {
        display: block;
        border: 1px solid $black;
        text-align: center;
        padding: 1rem 0;
        font-size: $font-size-base*3;
        text-decoration: none;
        line-height: 1;
        transition: color, background 0.25s;
        position: relative;
        &:hover,
        &:focus,
        &:active {
            color: $white;
            background-color: $black;
        }
    }
    .gold::after {
        content: $icon-ticket;
        font-family: 'icomoon';
        color: $vegas-gold;
        position: absolute;
        z-index: 1;
        top: 0.5rem;
        right: 0.5rem;
        display: inline-block;
        font-size: 2.5rem;
    }
    @include media-breakpoint-up(sm) {
        @include make-col(6);
        @include make-col-offset(3);
    }
}
