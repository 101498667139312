.table {
    td {
        text-align: right;
    }
    th {
        text-transform: uppercase;
        font-family: $headings-font-family;
    }
    tr.active {
        background-color: $black;
        color: $white;
    }
    th,
    td {
        border-color: $gray;
    }
    tbody {
        border-bottom: 1px solid $brand-secondary;
    }
}

.other-hours .table th {
    text-transform: inherit;
}
